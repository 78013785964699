
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_WAREHOUSE_DIFF_URL = BASE_API_URL+"warehouse_diffs"

@Component
export default class WarehouseDiffsMixin extends Vue {

  getWarehouseDiffs(data: any, tag: string) {
    return this.$httpGetQuery ( BASE_WAREHOUSE_DIFF_URL, tag,data);
  }

}

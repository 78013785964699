

































































































import { Source } from "@/models";

import { Component, Mixins, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VDatePicker from "@/components/DatePicker.vue";
import WarehouseDiffsMixin from "@/mixins/http/WarehouseDiffsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { WarehouseItem } from "@/models";
import EmptyView from "@/components/EmptyView.vue";
import { WarehouseDiff } from "@/models";
import { easync } from "@/utils/http";
import WarehouseItemSelect from "@/components/WarehouseItem/Select.vue";
import { sourceStore } from "@/store/typed";

const getTag = "get_warehouse_items";

@Component({
  components: {
    EmptyView,
    WarehouseItemSelect,
    VDatePicker,
    VPageContentSticky,
    DataTable,
  },
})
export default class WarehouseDiffList extends Mixins(WarehouseDiffsMixin) {
  @RegisterHttp(getTag) getRequest: RequestData;

  items: WarehouseDiff[] = [];
  page = 1;

  range: any = null;
  warehouseItemId: string = null;

  columns = [
    { name: "moveableType", label: "Tipo" },
    { name: "name", label: "Nome" },
    { name: "label", label: "Note" },
    { name: "createdAt", label: "Data" },
    { name: "value", label: "Valore" },
  ];

  search: string | null = null;

  onDateSelected(data: any) {
    this.page = 1;
    this.range = data.range;
    this.loadItems();
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  getMoveableType(type: string) {
    switch (type) {
      case "OrderLine":
        return "Linea d'ordine";
      case "Order":
        return "Ordine";
    }
  }

  onWarehouseItemSelected(id: string) {
    this.warehouseItemId = id;
    this.loadItems();
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  onSourceSet(source: Source | undefined) {
    if (source) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  get payload() {
    const data = {
      per: 20,
      page: this.page,
      filter: {
        term: this.search,
      },
    }

    if (this.range) {
      (data.filter as any).range = { from: this.range.from, to: this.range.to };
    }

    if (this.warehouseItemId) {
      (data.filter as any).warehouseItem = this.warehouseItemId;
    }

    return data;
  }

  async loadItems() {
    const [res, errors] = await easync(
      this.getWarehouseDiffs(this.payload, getTag)
    );

    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...res);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }
}
